export default {
  'environment': 'dev',
  'basePath': window.location.protocol + '//beta-api.leadgenapp.io/api',
  'recaptchaSiteKey': '6LdAEGkpAAAAACTzsAs3FnaSz7cknvIVu7o0tXqI',
  'pagesUrl': window.location.protocol + '//beta-pages.leadgenapp.io',
  'formsUrl': window.location.protocol + '//beta-forms.leadgenapp.io',
  'proofsUrl': window.location.protocol + '//beta-proofs.leadgenapp.io',
  'scriptsUrl': window.location.protocol + '//beta-scripts.leadgenapp.io',
  'stripePublicKey': 'pk_test_YFqP01U3tLiimhs24cmtaDT8',
  'sampleCsvUrl': window.location.protocol + '//' + window.location.host + '/static/sample/SampleTemplate.csv',
  'paddle': {
    'vendorId': 1189,
    'trialPlanId': 23499,
    'trialScaleId': 32674,
    'trialEnterpriseId': 32676,
    'trialAnnualProId': 32672,
    'trialAnnualScaleId': 32675,
    'trialAnnualEnterpriseId': 32677,
    'planId': 8687,
    'scaleId': 8694,
    'enterpriseId': 32678,
    'annualProId': 15628,
    'annualScaleId': 15629,
    'annualEnterpriseId': 32679,
    'singlePlanId': 47202,
    'starterPlanId': 70120
  },
  'registration': {
    'recaptchaEnabled': true
  }
}
